import React from 'react'

const CommunityDetails = () => {
  return (
    <div>
        Commuunity Details
      
    </div>
  )
}

export default CommunityDetails
