import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../firebase'; // Make sure to import db
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgLogin from "../images/resource/login.jpg";

import logo from '../dashboardstyle/images/gradient logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, PulseLoader } from 'react-spinners'; // Import the spinner
import { useAuth } from '../auth/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const { setPrimaryGoal } = useAuth(); // Add setPrimaryGoal to the context

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredential.user.emailVerified) {
        await sendEmailVerification(userCredential.user);
        toast.warning("Please verify your email. A new verification email has been sent.");
        navigate('/verify'); // Navigate to the verify page
        return;
      }

      // Fetch the primary goal
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      const userData = userDoc.data();
      if (userData && userData.primaryGoal) {
        setPrimaryGoal(userData.primaryGoal);
        navigate(userData.primaryGoal);
      } else {
        navigate('/primary-goal');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Set loading to false after action completes
    }
  };

  return (
    <div className='back-holderz'>
      <div className="form-back-drop"></div>

      
    


      <div class="fix-wrapper login-auth-screens">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6">
                    <div class="card mb-0 h-auto">
                        <div class="card-body">
                            <div class="text-center mb-3">
                                <a ><img class="logo-auth" src={logo} alt="" width='50'/></a>
                            </div>
                            <h5 class="text-center mb-4">Sign in your account!</h5>
                            <form onSubmit={handleLogin}>
                                <div class="form-group mb-4">
                                    <label class="form-label" for="username">Username</label>

                                    <input 
                                    type="text" 
                                    name="email"
                                    class="form-control" 
                                    placeholder="Enter Business Email ID"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    
                                    />


                                </div>
                                <div class="mb-sm-4 mb-3 position-relative password-field">
                                    <label class="form-label" for="dlab-password">Password</label>

                                    <input 
                                    type={showPassword ? "text" : "password"}
                                      name="password"
                                      placeholder="Enter Password"
                                      value={password}
                                    class="form-control"
                                    onChange={(e) => setPassword(e.target.value)}
                          required
                                   />

                           


                                    <span class="show-passz eye">
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                            </span>
                                    
                                </div>
                                <div class="form-row d-flex flex-wrap justify-content-between mb-2">
                                    <div class="form-group mb-sm-4 mb-1">
                                        <div class="form-check custom-checkbox ms-1">
                                            <input type="checkbox" class="form-check-input" id="basic_checkbox_1"/>
                                            <label class="form-check-label" for="basic_checkbox_1">Remember my preference</label>
                                        </div>
                                    </div>
                                    <div class="form-group ms-2">
                                        <a href="javascript:void(0)">
                                        <Link to="/reset-password">
                        Forgot Password
                      </Link>
                                          
                                          </a>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary btn-block"> {loading ? <PulseLoader size={9} color="#fff" /> : "Continue"}</button>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                                <p>Don't have an account? <a class="text-primary" href="javascript:void(0)" onClick={() => navigate('/sign-up')}>Sign up</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  );
};

export default Login;
