import React, { useState } from 'react';
import { auth, sendPasswordReset } from '../firebase';
import logo from '../dashboardstyle/images/gradient logo.png'

import imgBack from '../images/background/5.jpg'
import imgLogin from "../images/resource/login.jpg";
import { Navigate, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';



const ResetPassword = () => {

    let cuser = auth.currentUser;
    const [email, setEmail] = useState("");
      const navigate = useNavigate();

  return (
    <div className='back-holderz'>


<div class="fix-wrapper login-auth-screens">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6">
                    <div class="card mb-0 h-auto">
                        <div class="card-body">
                            <div class="text-center mb-3">
                                <a ><img class="logo-auth" src={logo} alt="" width='50'/></a>
                            </div>
                            <h5 class="text-center mb-4">Forgot Password!</h5>
                         
                   
                                <div class="form-group mb-4">
                                  

                                    <input 
                                    type="text" 
                                    name="email"
                                    class="form-control" 
                                    placeholder="Enter Business Email ID"
                                    value={email}
                                
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    
                                    />


                                </div>
                    
                               
                                <div class="text-center">
                                    <button class="btn btn-primary btn-block" onClick={() => sendPasswordReset(email)}>Reset Password</button>
                                </div>
                         
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
     

      
    </div>
  );
};

export default ResetPassword;
