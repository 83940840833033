import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signUpimg from '../images/resource/sign-up.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, PulseLoader } from 'react-spinners'; // Import the spinner
import logo from '../dashboardstyle/images/gradient logo.png'

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    if (!formData.firstname) errors.firstname = "First name is required";
    if (!formData.lastname) errors.lastname = "Last name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) errors.confirmPassword = "Passwords do not match";

    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // if (!passwordRegex.test(formData.password)) {
    //   errors.password = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
    // }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Set loading to true
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await updateProfile(userCredential.user, { displayName: `${formData.firstname} ${formData.lastname}` });
        await sendEmailVerification(userCredential.user);
        toast.success("Verification email sent. Please check your inbox.");
        navigate('/verify');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false); // Set loading to false after action completes
      }
    } else {
      Object.values(validationErrors).forEach(error => toast.error(error));
    }
  };

  return (

    <div className='back-holderz'>


  


      <div class="fix-wrapper login-auth-screens">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="card mb-0 h-auto">
                        <div class="card-body">
                            <div class="text-center mb-3">
                                <a ><img class="logo-auth" src={logo} alt="" width='50'/></a>
                            </div>
                            <h5 class="text-center mb-4">Create an account!</h5>
                            <div class="text-center ms-2">
                                        <a href="javascript:void(0)">
                                        <Link to="/login">Already have an account? Sign in</Link>
                                          
                                          </a>
                                    </div>
                                    <br/>
                            <form onSubmit={handleSubmit}>

                            <div class="form-group mb-4">
                                   

                                    <input 
                                    type="text" 
                                       name="firstname"
                                    class="form-control" 
                              placeholder="First Name"
                              value={formData.firstname}
                              onChange={handleChange}
                                    required
                                    
                                    />
                                </div>


                                <div class="form-group mb-4">
                                   

                                    <input 
                                    type="text" 
                                 name="lastname"
                                    class="form-control" 
                                     placeholder="Last Name"
                                     value={formData.lastname}
                                     onChange={handleChange}
                                    required
                                    
                                    />
                                </div>


                                <div class="form-group mb-4">
                                   

                                    <input 
                                    type="text" 
                                    name="email"
                                    class="form-control" 
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    required
                                    
                                    />
                                </div>


                                <div class="mb-sm-4 mb-3 position-relative password-field">
                                    

                                    <input 
                                    type={showPassword ? "text" : "password"}
                                      name="password"
                                  placeholder="Create Password"
                                  value={formData.password}
                                    class="form-control"
                                    onChange={handleChange}
                          required
                                   />

<span class="show-passz eye">
                                     <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      </span>

                                    
                                </div>


                                <div class="mb-sm-4 mb-3 position-relative password-field">
                                    

                                    <input 
                                  type={showConfirmPassword ? "text" : "password"}
                                       name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={formData.confirmPassword}
                                    class="form-control"
                                    onChange={handleChange}
                          required
                                   />

<span class="show-passz eye">
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEye : faEyeSlash}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      />
                      </span>
                                    
                                </div>


                                <div class="form-row d-flex flex-wrap justify-content-between mb-2">
                                
                                  
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary btn-block">   {loading ? <PulseLoader size={10} color="#fff" /> : "Sign Up"}</button>
                                </div>
                            </form>
                            <br/>
                            <div className='loginform-terms'>
                    <small>By continuing, you agree to the <a href=''>Privacy Policy</a> and the <a href=''>Terms of Use</a> </small>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  );
};

export default SignUp;
