import React, { useState } from 'react';
import '../dashboardstyle/css/style.css';
import DashHeader from './DashHeader';

import MeetingsDash from './MeetingsDash';
import OrganiseSide from './OrganiseSide';
import DashboardOrg from '../organiserDashComponents/DashboardOrg';
import EventsOrg from '../organiserDashComponents/EventsOrg';
import LibraryOrg from '../organiserDashComponents/LibraryOrg';
import IntegrationsOrg from '../organiserDashComponents/IntegrationsOrg';
import BillingOrg from '../organiserDashComponents/BillingOrg';
import AnalyticsOrg from '../organiserDashComponents/AnalyticsOrg';
import AudienceOrg from '../organiserDashComponents/AudienceOrg';
import TeamOrg from '../organiserDashComponents/TeamOrg';
import Profile from '../organiserDashComponents/Profile';
import ChangePassword from '../organiserDashComponents/ChangePassword';
import VirtualSpace from '../organiserDashComponents/Events/VirtualSpace';
import CompanyProfile from '../organiserDashComponents/CompanyProfile';
import CompanyWebsite from '../organiserDashComponents/CompanyWebsite';

const OrganiserDash = () => {
  const [activeTab, setActiveTab] = useState('Dashboard');

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <DashboardOrg/>;
        case 'VirtualSpace':
          return <VirtualSpace/>;
      case 'Events':
        return <EventsOrg/>;
      case 'Library':
        return <LibraryOrg/>;
      case 'Team':
        return <TeamOrg/>;
      case 'Integrations':
        return <IntegrationsOrg/>;
      case 'Billing':
        return <BillingOrg/>;
      case 'Analytics':
        return <AnalyticsOrg/>;
      case 'Audience':
        return <AudienceOrg/>;

        case 'ChangePassword':
          return <ChangePassword/>;

          case 'CompanyProfile':
            return <CompanyProfile/>;

        case 'Profile':
          return <Profile/>;

          case 'CompanyWebsite':
            return <CompanyWebsite/>;

          


      default:
        return  <DashboardOrg/>;;
    }
  };

  return (
    <div>
 

      <DashHeader  activeTab={activeTab}/>

      <OrganiseSide activeTab={activeTab} setActiveTab={setActiveTab} />

      <div class="content-body default-height">
        
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-12">
						<div className="row">
            {renderContent()}
						</div>
					</div>
				</div>
            </div>
        </div>


  


      </div>

  );
};

export default OrganiserDash;
