import React from 'react'

const ExploreEvents = () => {
  return (
    <div>
      ExploreEvents
    </div>
  )
}

export default ExploreEvents
