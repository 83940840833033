import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const ModeSwitcher = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // On mount, check for existing cookie and apply theme
    const savedTheme = Cookies.get("theme-version");
    const isDark = savedTheme === "dark";
    setIsDarkMode(isDark);
    document.body.setAttribute("data-theme-version", isDark ? "dark" : "light");
  }, []);

  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);

    // Update body attribute and cookie
    const theme = newMode ? "dark" : "light";
    document.body.setAttribute("data-theme-version", theme);
    Cookies.set("theme-version", theme, { expires: 7 });
  };

  return (
    <div className="mode_switcher my_switcher">
      <button className={`dz-theme-mode ${isDarkMode ? "active" : ""}`} onClick={toggleTheme}>
        {isDarkMode ? (
          <div className="mode-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fas fa-moonz dark"
              viewBox="0 0 512 512"
              id="icon-light"
              width="24"
              height="24"
            >
              <path
                d="M256 48v48M256 416v48M403.08 108.92l-33.94 33.94M142.86 369.14l-33.94 33.94M464 256h-48M96 256H48M403.08 403.08l-33.94-33.94M142.86 142.86l-33.94-33.94"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
              />
              <circle
                cx="256"
                cy="256"
                r="80"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="32"
              />
            </svg>
            <span className="mode-text">Light</span>
          </div>
        ) : (
          <div className="mode-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fas fa-moonz light"
              viewBox="0 0 512 512"
              id="icon-dark"
              width="24"
              height="24"
            >
              <path
                d="M160 136c0-30.62 4.51-61.61 16-88C99.57 81.27 48 159.32 48 248c0 119.29 96.71 216 216 216 88.68 0 166.73-51.57 200-128-26.39 11.49-57.38 16-88 16-119.29 0-216-96.71-216-216z"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
              />
            </svg>
            <span className="mode-text">Dark</span>
          </div>
        )}
      </button>

      {/* CSS Styles */}
      <style jsx>{`
        .mode-container {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .mode-text {
          font-size: 12px;
          color: ${isDarkMode ? "#fff" : "#fff"};
          transition: color 0.3s ease-in-out;
        }

        /* Default button styles */
        .dz-theme-mode {
          background: ${isDarkMode ? "#2C2C2C" : "#fd295d"};
          color: ${isDarkMode ? "#fff" : "#fff"};
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px 16px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: 90px;
          transition: background 0.3s, box-shadow 0.3s;
          box-shadow: ${isDarkMode
            ? "0px 6px 34px rgba(255, 255, 255, 0.3)"
            : "0px 6px 34px rgba(215, 216, 222, 0.3)"};
        }

        /* Light Mode */
        [data-theme-version="light"] .dz-theme-mode {
          background: #fd295d !important;
          color: #fff !important;
          box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.15) !important;
        }

        /* Dark Mode */
        [data-theme-version="dark"] .dz-theme-mode {
          background: #2C2C2C !important;
          color: #fff !important;
          box-shadow: 0px 6px 34px rgba(255, 255, 255, 0.3) !important;
        }
      `}</style>
    </div>
  );
};

export default ModeSwitcher;
