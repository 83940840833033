import React, { useState } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import chartImg from '../dashboardstyle/images/chart.png';

import bigWind from '../dashboardstyle/images/big-wind.png'

Chart.register(...registerables);

const DashboardOrg = () => {
  const [showNumbers, setShowNumbers] = useState(true);
  const [showAnalytics, setShowAnalytics] = useState(true);

  const data = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Running',
        data: [40, 20, 10, 50, 60, 90, 80],
        backgroundColor: 'rgba(255, 162, 109, 1)',
        borderRadius: 20,
        borderSkipped: false,
        barThickness: 20, // Adjust bar thickness here
        maxBarThickness: 20
      },
      {
        label: 'Cycling',
        data: [40, 50, 10, 20, 50, 90, 80],
        backgroundColor: 'rgba(255, 94, 210, 1)',
        borderRadius: 20,
        borderSkipped: false,
        barThickness: 20, // Adjust bar thickness here
        maxBarThickness: 20
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        barPercentage: 0.8, // Space between bars
        categoryPercentage: 0.8, // Space between bars
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 20, // This will add rounded corners
        borderSkipped: false, // This ensures the border is applied properly
      },
    },
  };

  const doughnutData = {
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        data: [5, 3],
        backgroundColor: ['rgba(141, 20, 255, 1)', 'rgba(241, 234, 255, 1)'],
        hoverBackgroundColor: ['rgba(136,108,192,0.8)', 'rgba(241, 234, 255,0.8)'],
        borderWidth: 0,
      },
    ],
  };

  const doughnutOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const doughnutDataB = {
    labels: ['Webinar', 'Virtual Event', 'Hybrid Event', 'In-Person Event'],
    datasets: [
      {
        data: [27, 11, 15, 25],
        backgroundColor: [
          '#8d14ff', // Webinar
          '#26E023', // Virtual Event
    
          '#FFDA7C', // Hybrid Event
          '#ce2b7c', // In-Person Event

        ],
        hoverBackgroundColor: [
          '#8d14ff',
          '#26E023',
  
          '#FFDA7C',
          '#ce2b7c',
        ],
        borderWidth: 0,
      },
    ],
  };


  return (
    <div>
      
      <div className='row'>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card tryal-gradient-events">
                <div className="card-body tryal row">
                  <div className="col-xl-7 col-sm-7">
                    <h2 className="mb-0">Manage your events in one place</h2>
                    <span>Let Genesis help manage your events automatically with our best AI systems </span>
                    <a href="javascript:void(0);" className="btn btn-rounded">Create an event</a>
                  </div>
                  <div className="col-xl-5 col-sm-5 ">
                    <img src={chartImg} alt="" className="sd-shape" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-6 col-sm-6">
                  <div className="widget-stat card bg-teal">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="fas fa-calendar-alt"></i>
                        </span>
                        <div className="media-body text-white text-end">
                          <h6 className="mb-1 text-white">Total Events</h6>
                          <h3 className="text-white">76</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="widget-stat card bg-info-light">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="fas fa-users"></i>
                        </span>
                        <div className="media-body text-white text-end">
                          <h6 className="mb-1 text-white">Audience</h6>
                          <h3 className="text-white">18</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="widget-stat card bg-warning-light">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="fas fa-user-tie"></i>
                        </span>
                        <div className="media-body text-white text-end">
                          <h6 className="mb-1 text-white">Team Members</h6>
                          <h3 className="text-white">31</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="widget-stat card bg-primary-b">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="fas fa-chart-line"></i>
                        </span>
                        <div className="media-body text-white text-end">
                          <h6 className="mb-1 text-white">Upcoming Events</h6>
                          <h3 className="text-white">46</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


<div className='col-lg-6'>
<div className='row'>

<div class="col-xl-6 col-xxl-12 col-sm-6">
												<div class="card">
													<div class="card-header border-0 pb-0">
														<div>
															<h4 class="card-title">Priority Events</h4>
															<p class="mb-0">Lorem ipsum dolor sit amet</p>
														</div>
													</div>
													<div class="card-body pb-0 pt-3">
														<div class="project-details"> 
															<div class="d-flex align-items-center justify-content-between">
																<div class="d-flex align-items-center">
																	<div class="big-wind">
																		<img src={bigWind} alt=""/>
																	</div>
																	<div class="ms-3">
																		<h5 class="mb-1">Big Wind</h5>
																		<p class="mb-0">Creative Agency</p>
																	</div>
																</div>	
																<div class="dropdown">
																	<div class="btn-link" data-bs-toggle="dropdown">
																		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
																			<circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
																			<circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
																		</svg>
																	</div>
																	
																</div>
															</div>
															
															<div class="projects">
																<span class="badge badge-warning light me-3">SEO</span>
																<span class="badge badge-danger light">MARKETING</span>
															</div>
															<div class="mt-3">
																<div class="progress default-progress">
																	<div class="progress-bar bg-gradient1 progress-animated" style={{ width: '45%', height: '5px' }} role="progressbar">
																		<span class="sr-only">45% Complete</span>
																	</div>
																</div>
																<div class="d-flex align-items-end mt-3 justify-content-between">
																	<p class="mb-0"><strong class="text-black me-2">12</strong>Task Done</p>
																	<p class="mb-0">Due date: 12/05/2020</p>
																</div>
															</div>
														</div>	
														<hr/>
														<div class="project-details"> 
															<div class="d-flex align-items-center justify-content-between">
																<div class="d-flex align-items-center">
																	<span class="big-wind">
																		<img src={bigWind} alt=""/>
																	</span>
																	<div class="ms-3">
																		<h5 class="mb-1">Circle Hunt</h5>
																		<p class="mb-0">Creative Agency</p>
																	</div>
																</div>	
																<div class="dropdown">
																	<div class="btn-link" data-bs-toggle="dropdown">
																		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
																			<circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
																			<circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
																		</svg>
																	</div>
																	
																</div>
															</div>
															{/* <h5 class="mt-3">Redesign Landing Page Website for Company Profile</h5> */}
															<div class="projects">
																<span class="badge badge-primary light me-3">UI/UX</span>
																<span class="badge badge-danger light">WEBSITE</span>
															</div>
															<div class="mt-3">
																<div class="progress default-progress">
																	<div class="progress-bar bg-gradient1 progress-animated" style={{ width: '45%', height: '5px' }} role="progressbar">
																		<span class="sr-only">45% Complete</span>
																	</div>
																</div>
																<div class="d-flex align-items-end mt-3 justify-content-between">
																	<p class="mb-0"><strong class="text-black me-2">12</strong>Task Done</p>
																	<p class="mb-0">Due date: 12/05/2020</p>
																</div>
															</div>
														</div>	
														<hr/>
													</div>

													<br/>

													<div class="card-footer pt-0 border-0">
														<a href="javascript:void(0);" class="btn light btn-primary btn-rounded mb-sm-0 mb-2 me-2 btn-sm  d-block">Pin other Events</a>
													</div>
												
												</div>
											</div>

		<div className="col-xl-6 col-xxl-12 col-sm-6">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <div>
            <h4 className="card-title">Events Categories</h4>
            <p className="mb-0">Lorem ipsum dolor sit amet</p>
          </div>
        </div>
        <div className="card-body pb-0">
          <div id="emailchart" style={{ width: 120, height: 120 }}>
            <Doughnut data={doughnutDataB} options={doughnutOptions} />
          </div>
          <div className="mb-3 mt-4">
            <h5 >Legend</h5>
          </div>
          <div className="email-legend">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fs-16 text-gray">
                <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="6" fill="#8d14ff"></rect>
                </svg>
				Webinar

              </span>
              <h5 className="mb-0 font-w600">763</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fs-16 text-gray">
                <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="6" fill="#26E023"></rect>
                </svg>
				Virtual Event
              </span>
              <h5 className="mb-0 font-w600">321</h5>
            </div>
         
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fs-16 text-gray">
                <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="6" fill="#FFDA7C"></rect>
                </svg>
				Hybrid Event
              </span>
              <h5 className="mb-0 font-w600">154</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-0 spam">
              <span className="fs-16 text-gray">
                <svg className="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="6" fill="#ce2b7c"></rect>
                </svg>
				In-Person Event
              </span>
              <h5 className="mb-0 font-w600">696</h5>
            </div>
          </div>
        </div>
		<br/>

<div class="card-footer pt-0 border-0">
	<a href="javascript:void(0);" class="btn light btn-primary btn-rounded mb-sm-0 mb-2 me-2 btn-sm  d-block">Create Another Event</a>
</div>
      </div>
    </div>

	
		</div>

</div>
		

        <div className="col-xl-6">
          <div className="card">
            <div className="card-header border-0 pb-0 flex-wrap">
              <h4 className="card-title">Events Statistics</h4>
              <div className="d-flex align-items-center mt-3 project-tab">
                <div className="card-tabs mt-sm-0 me-3">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active" data-bs-toggle="tab" href="#monthly" role="tab" aria-selected="true">Monthly</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" data-bs-toggle="tab" href="#weekly" role="tab" aria-selected="false" tabIndex="-1">Weekly</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link" data-bs-toggle="tab" href="#today" role="tab" aria-selected="false" tabIndex="-1">Today</a>
                    </li>
                  </ul>
                </div>
                <div className="dropdown ms-2">
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex">
                  {/* <div className="d-inline-block position-relative donut-chart-sale mb-3" style={{ width: 80, height: 80 }}>
                    <Doughnut data={doughnutData} options={doughnutOptions} />
                  </div> */}
                  <div className="ms-3">
				  <i style={{color:'#ce2b7c'}} className="fas fa-calendar"></i>
                    <h4 className="fs-24 mb-0" style={{ fontWeight:'bold'}}>274</h4>
                    <p className="mb-0">Total Events</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex me-5">
                    <div className="mt-2">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.5" cy="6.5" r="6.5" fill="#FFCF6D"></circle>
                      </svg>
                    </div>
                    <div className="ms-3">
                      <h4 className="fs-24 mb-0 " style={{ fontWeight:'bold'}}>246</h4>
                      <p className="mb-0">On Going</p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mt-2">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.5" cy="6.5" r="6.5" fill="#FFA7D7"></circle>
                      </svg>
                    </div>
                    <div className="ms-3">
                      <h4 className="fs-24 mb-0" style={{ fontWeight:'bold'}}>28</h4>
                      <p className="mb-0">Unfinished</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="chartBar" className="chartBar" style={{ minHeight: 315 }}>
                <Bar data={data} options={options} />
              </div>

            </div>
          </div>
        </div>

        


		<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">All Events</h4>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-responsive-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Event Name</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-black">1</th>
                            <td>Color Tea Shirt For Man</td>
                            <td>05 January 2024</td>
                            <td><span class="badge badge-primary light">Webinar</span></td>
                            <td class="color-primary">Free</td>
                        </tr>
                        <tr>
                            <th class="text-black">2</th>
                            <td>Color Tea Shirt For Women</td>
                            <td>05 January 2024</td>
                            <td><span class="badge badge-success light">In-Person</span></td>
                            <td class="color-success">R55.32</td>
                        </tr>
                        <tr>
                            <th class="text-black">3</th>
                            <td>Blue Backpack For Baby</td>
                            <td>05 January 2024</td>
                            <td><span class="badge badge-danger light">Virtual</span></td>
                            <td class="color-danger">Free</td>
                        </tr>
                        <tr>
                            <th class="text-black">4</th>
                            <td>Business Networking Event</td>
                            <td>15 February 2024</td>
                            <td><span class="badge badge-warning light">In-Person</span></td>
                            <td class="color-warning">R120.00</td>
                        </tr>
                        <tr>
                            <th class="text-black">5</th>
                            <td>Tech Conference 2024</td>
                            <td>20 March 2024</td>
                            <td><span class="badge badge-info light">Virtual</span></td>
                            <td class="color-info">R75.00</td>
                        </tr>
                        <tr>
                            <th class="text-black">6</th>
                            <td>Cooking Workshop</td>
                            <td>10 April 2024</td>
                            <td><span class="badge badge-primary light">Webinar</span></td>
                            <td class="color-primary">Free</td>
                        </tr>
                        <tr>
                            <th class="text-black">7</th>
                            <td>Fitness Bootcamp</td>
                            <td>25 May 2024</td>
                            <td><span class="badge badge-success light">In-Person</span></td>
                            <td class="color-success">R150.00</td>
                        </tr>
                        <tr>
                            <th class="text-black">8</th>
                            <td>Art Exhibition</td>
                            <td>30 June 2024</td>
                            <td><span class="badge badge-danger light">Virtual</span></td>
                            <td class="color-danger">Free</td>
                        </tr>
                        <tr>
                            <th class="text-black">9</th>
                            <td>Music Festival</td>
                            <td>15 July 2024</td>
                            <td><span class="badge badge-warning light">In-Person</span></td>
                            <td class="color-warning">R200.00</td>
                        </tr>
                        <tr>
                            <th class="text-black">10</th>
                            <td>Yoga Retreat</td>
                            <td>25 August 2024</td>
                            <td><span class="badge badge-info light">Webinar</span></td>
                            <td class="color-info">Free</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

		
      </div>
    </div>
  );
};

export default DashboardOrg;
