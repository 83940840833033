import React from 'react'
import Avatar from 'react-avatar'

const CompanyProfile = () => {
  return (
    <div>
      
      <div className="dash-backgroundz">
      <div className="content-bodyz">
        <div className="container-fluid">

        <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>

          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Company
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Company Profile</a>
          </li>
        </ol>
      </div>
          <div className="row">
           
            <div className="col-xl-12 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Company Overview</h6>
                </div>
                <form className="profile-form" >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                       
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Tagline/Slogan</label>
                        <input
                          type="text"
                          className="form-control"
                        
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Founded Date</label>
                        <input
                          type="text"
                          className="form-control"
                        
                        />
                      </div>



                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Type of Business</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>
                    
                      <div className="col-lx-6 m-b30">
                        <label className="form-label">About Company</label>
                        <textarea
                          rows="8"
                          className="form-control"
                  
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">
                      Update Company Overview
                    </button>
                  </div>
                </form>
              </div>
            </div>


            <div className="col-xl-12 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Contact Information</h6>
                </div>
                <form className="profile-form" >
                  <div className="card-body">
                    <div className="row">



                    <div className="">
                        <h5 style={{fontWeight: 'bold'}}>Address</h5>
                      </div>
                      <br/>
                      <br/>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Address Line 1</label>
                        <input
                          type="text"
                          className="form-control"
                       
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Address Line 2</label>
                        <input
                          type="text"
                          className="form-control"
                        
                        />
                      </div>
                      <div className="col-lx-12 m-b30">
                        <label className="form-label">State/Town</label>
                        <input
                          type="text"
                          className="form-control"
                        
                        />
                      </div>

                      <div className="">
                        <h5 style={{fontWeight: 'bold'}}>Contact</h5>
                      </div>
                      <br/>
                      <br/>


                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Province</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Website</label>
                        <input
                          type="text"
                          className="form-control"
                          
                        />
                      </div>




                      <div className="">
                        <h5 style={{fontWeight: 'bold'}}>Social Media</h5>
                      </div>
                      <br/>
                      <br/>

                      <div className="col-sm-4 m-b30">
                        <label className="form-label">Facebook</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>

                      <div className="col-sm-4 m-b30">
                        <label className="form-label">Instagram</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>

                      <div className="col-sm-4 m-b30">
                        <label className="form-label">TikTok</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>


                      <div className="col-sm-4 m-b30">
                        <label className="form-label">Twitter</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>

                      <div className="col-sm-4 m-b30">
                        <label className="form-label">WhatsApp</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>

                      <div className="col-sm-4 m-b30">
                        <label className="form-label">LinkedIn</label>
                        <input
                          type="text"
                          className="form-control"
                         
                        />
                      </div>
                    
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">
                      Update Contact Information
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-xl-12 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Complience</h6>
                </div>
                <form className="profile-form" >
                  <div className="card-body">
                    <div className="row">


                      <div className="col-sm-6 m-b30">
                        <label className="form-label">CK/CIPC/Company Registration Document</label>
                      <input class="form-control" type="file" id="formFile"/>
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Proof of Address</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Tax Certificate/Pin</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Tax Certificate Exp Date:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder='03-10-2029'
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">B-BBEE Certificate</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>


                      <div className="col-sm-6 m-b30">
                        <label className="form-label">B-BBEE Exp Date:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder='03-10-2029'
                         
                        />
                      </div>


                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Bank Confirmation</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Directors ID Documents</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>

                    

                

                    
                    
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">
                      Update Complience
                    </button>
                  </div>
                </form>
              </div>
            </div>


            <div className="col-xl-12 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Visuals and Media</h6>
                </div>
                <form className="profile-form" >
                  <div className="card-body">
                    <div className="row">


                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Company Logo</label>
                      <input class="form-control" type="file" id="formFile"/>
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Images</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>

                      <div className="col-xl-12 m-b30">
                        <label className="form-label">Videos</label>
                        <input class="form-control" type="file" id="formFileMultiple" multiple=""/>
                      </div>

                    
                    
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">
                      Update Visuals and Media
                    </button>
                  </div>
                </form>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default CompanyProfile
