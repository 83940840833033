import React from 'react'

const Footer = () => {
  return (
    <div>
      <div class="main-footer style-two">
<div class="anim-icons">
<span class="icon icon-twist-line-1 wow zoomIn"></span>
<span class="icon icon-circle-6 wow zoomIn" data-wow-delay="400ms"></span>
<span class="icon icon-twist-line-2 wow zoomIn" data-wow-delay="800ms"></span>
<span class="icon icon-circle-8 wow zoomIn" data-wow-delay="1200ms"></span>
</div>
<div class="auto-container">
<div class="sec-title text-center">
<span class="title">Get The Latest Offers</span>
<h2>Subscribe To Newsletter</h2>
</div>

<div class="newsletter-form">
<form method="post" action="">
<div class="form-group">
<input type="email" name="field-name" placeholder="email@gmail.com" r/>
<button type="submit" class="theme-btn btn-style-three">Send</button>
</div>
</form>
</div>

<div class="social-links">
<ul>
<li><a href="#"><span class="fab fa-facebook-f"></span></a></li>


<li><a href="#"><span class="fab fa-instagram"></span></a></li>
<li><a href="#"><span class="fab fa-twitter"></span></a></li>
<li><a href="#"><span class="fab fa-linkedin-in"></span></a></li>
</ul>
</div>

<div class="footer-bottom clearfix text-center">
<div class="copyright">eConnect &copy; 2025. All Rights Reserved.</div>

</div>

</div>
</div>

    </div>
  )
}

export default Footer
