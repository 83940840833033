import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { sendEmailVerification, reload } from "firebase/auth";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";

import imgVeri from "../images/resource/about-img-1.jpg";

const Verify = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(60);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      toast.success("Verification email resent. Please check your inbox.");
      setTimeLeft(60);
      setCanResend(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const checkEmailVerification = setInterval(async () => {
      await reload(auth.currentUser);
      if (auth.currentUser.emailVerified) {
        clearInterval(checkEmailVerification);

        // Check user's primary goal
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userData = userDoc.data();
        if (userData && userData.primaryGoal) {
          navigate(userData.primaryGoal);
        } else {
          navigate("/primary-goal");
        }
      }
    }, 3000); // Check every 3 seconds

    return () => clearInterval(checkEmailVerification);
  }, [navigate]);

  return (
    <div className="back-holderzzz">




      <div class="fix-wrapper login-auth-screens">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-6">
                    <div class="form-input-content text-center error-page">
                        <h1 class="  font-weight-bold"></h1>
                        <h4>Verify Your Email! <i class="fa fa-lock text-primary"></i></h4>
                        <br/>


                        <span>Verification email has been sent to the following
                        email address:</span>
                          <span style={{ color: "#ce2b7c", fontWeight: 'bolder', fontSize: '18px' }}>
                             {" "} {auth.currentUser.email}
                          </span>
                          <br/> <br/>


                        <p> Please follow the instructions in the email to verify
                          your account. If you cannot find it in your inbox,
                          check the junk/spam folder.</p>
						<div>


                          
            <div className="btn-box">
                          <button
                            className="theme-btn btn-style-three"
                            onClick={resendVerificationEmail}
                            disabled={!canResend}
                          >
                            {canResend
                              ? "Resend link"
                              : `Resend link in ${timeLeft} Seconds`}
                          </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>















    </div>
  );
};

export default Verify;
