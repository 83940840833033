import React from 'react'

const ExploreOrganisations = () => {
  return (
    <div>
      Explore Organisations
    </div>
  )
}

export default ExploreOrganisations
