import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage, auth } from '../../../firebase';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Modal from 'react-modal';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';
import { Tooltip } from 'react-tooltip';

import imgDark from '../../../images/dark logo.png';
import EconnectMeetingModal from './EconnectMeetingModal';
import BackgroundStyleLight from '../../../components/BackgroundStyleLight';
import logo from '../../../images/Plain white.png';

import eLogo from '../../../dashboardstyle/images/gradient logo.png'

import bannerImg from '../../../dashboardstyle/images/banner.png'







const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }
};

const customStylesBio = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" }
};

const VirtualOfficeSpaceUnits = () => {
  const { spaceId } = useParams();
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isUserSeated, setIsUserSeated] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [isCheckingInvitation, setIsCheckingInvitation] = useState(true);
  const [isJitsiModalOpen, setIsJitsiModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");

  // Fetch user details on mount
  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
        }
      }
    };
    fetchUserData();
  }, []);

  // Check if the user is already seated
  useEffect(() => {
    const fetchUserSeatingStatus = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser || !spaceDetails) return;
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';
        const isUserCurrentlySeated = spaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
        setIsUserSeated(isUserCurrentlySeated);
      } catch (error) {
        console.error("Error fetching user seating status: ", error);
      }
    };
    if (spaceDetails) fetchUserSeatingStatus();
  }, [spaceDetails, userDetails]);

  // Fetch space details
  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        setLoading(true);
        setIsCheckingInvitation(true);
        const user = auth.currentUser;
        if (!user) {
          toast.error("You need to be logged in to access this space.");
          setIsCheckingInvitation(false);
          return;
        }
        const spaceDoc = doc(db, 'virtual-offices', spaceId);
        const docSnap = await getDoc(spaceDoc);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.participants?.includes(user.email)) {
            setIsInvited(true);
          } else {
            setIsInvited(false);
            return;
          }
          const unitsWithLogos = await Promise.all(
            data.units.map(async (unit) => {
              const tablesWithLogos = await Promise.all(
                unit.tables.map(async (table) => {
                  if (table.tableLogo) {
                    const tableLogoUrl = await getDownloadURL(ref(storage, table.tableLogo));
                    return { ...table, tableLogoUrl };
                  }
                  return table;
                })
              );
              return { ...unit, tables: tablesWithLogos };
            })
          );
          setSpaceDetails({ ...data, units: unitsWithLogos });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching space details:', error);
      } finally {
        setLoading(false);
        setIsCheckingInvitation(false);
      }
    };
    fetchSpaceDetails();
  }, [spaceId]);

  // Subscribe to realtime updates
  useEffect(() => {
    const spaceDocRef = doc(db, 'virtual-offices', spaceId);
    const unsubscribe = onSnapshot(spaceDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const updatedSpaceDetails = docSnapshot.data();
        setSpaceDetails(updatedSpaceDetails);
        const userFirstName = userDetails.firstName || 'Unknown';
        const userLastName = userDetails.lastName || 'Unknown';
        const isUserCurrentlySeated = updatedSpaceDetails.units.some(unit =>
          unit.tables.some(table =>
            table.occupiedChairs?.some(
              chair => chair.name === userFirstName && chair.surname === userLastName
            )
          )
        );
        setIsUserSeated(isUserCurrentlySeated);
      } else {
        console.log("Space document does not exist!");
      }
    });
    return () => unsubscribe();
  }, [spaceId, userDetails]);

  // Adjust table size after render and on resize
  const adjustTableSize = () => {
    document.querySelectorAll('.table-area').forEach(tableArea => {
      const topChairs = tableArea.querySelector('.row-chairs:first-child')?.children.length || 0;
      const bottomChairs = tableArea.querySelector('.row-chairs:last-child')?.children.length || 0;
      const leftChairs = tableArea.querySelector('.vertical-chairs:first-child')?.children.length || 0;
      const rightChairs = tableArea.querySelector('.vertical-chairs:last-child')?.children.length || 0;
      const maxTopBottom = Math.max(topChairs, bottomChairs);
      const maxLeftRight = Math.max(leftChairs, rightChairs);
      let width = 60;
      let height = 60;
      if (topChairs === 4 && bottomChairs === 4) {
        width = 110;
      } else if (maxTopBottom === 3) {
        width = 90;
      } else if (maxTopBottom === 2) {
        width = 80;
      } else {
        width = 60;
      }
      if (maxLeftRight >= 6) {
        height = 160;
      } else if (maxLeftRight === 5) {
        height = 150;
      } else if (maxLeftRight === 4) {
        height = 120;
      } else {
        height = 60;
      }
      if (topChairs === bottomChairs && leftChairs === rightChairs) {
        width = height = Math.max(width, height);
      }
      if (maxLeftRight > maxTopBottom) {
        height = Math.max(height, width + 40);
      }
      const tableElement = tableArea.querySelector('.table-rect');
      if (tableElement) {
        tableElement.style.width = `${width}px`;
        tableElement.style.height = `${height}px`;
      }
    });
  };

  // Call adjustTableSize after rendering and on window resize
  useEffect(() => {
    adjustTableSize();
  }, [spaceDetails, currentUnit]);

  useEffect(() => {
    window.addEventListener('resize', adjustTableSize);
    return () => window.removeEventListener('resize', adjustTableSize);
  }, []);

  // Calculate chair distribution (unchanged)
  const calculateChairDistribution = (totalChairs) => {
    const maxTopBottomChairs = 4;
    const maxLeftRightChairs = 6;
    const rows = { topRow: 0, bottomRow: 0, leftRow: 0, rightRow: 0 };
    if (totalChairs <= maxTopBottomChairs * 2 + maxLeftRightChairs * 2) {
      const perSide = Math.floor(totalChairs / 4);
      const remaining = totalChairs % 4;
      rows.topRow = Math.min(maxTopBottomChairs, perSide + (remaining > 0 ? 1 : 0));
      rows.bottomRow = Math.min(maxTopBottomChairs, perSide + (remaining > 1 ? 1 : 0));
      rows.leftRow = Math.min(maxLeftRightChairs, perSide + (remaining > 2 ? 1 : 0));
      rows.rightRow = Math.min(maxLeftRightChairs, perSide);
    }
    return rows;
  };

  // When a chair is clicked, either sit on it (if empty) or show details
  const renderChair = (occupiedChair, onSit) => {
    return (
      <div
        className="chair"
        onClick={() => {
          if (occupiedChair) {
            handleAvatarClick(occupiedChair);
          } else {
            onSit();
          }
        }}
      >
        {occupiedChair && (
          <Avatar
            name={`${occupiedChair.name} ${occupiedChair.surname}`}
            size="25"
       
          />
        )}
      </div>
    );
  };

  const handleUnitClick = (unitName) => {
    if (currentUnit) {
      toast.warning(`You are already in unit: ${currentUnit}. Please exit before joining another unit.`);
      return;
    }
    setSelectedUnit(unitName);
    setCurrentUnit(unitName);
  };

  const handleEnterRoom = () => {
    setIsModalOpen(false);
    setCurrentUnit(selectedUnit);
  };

  const handleSitOnChair = async (unitName, tableIndex, chairIndex) => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === unitName);
    if (!unit) { toast.error("Unit not found."); return; }
    const table = unit.tables[tableIndex];
    if (!table) { toast.error("Table not found."); return; }
    if (!table.occupiedChairs) { table.occupiedChairs = []; }
    const userFirstName = userDetails.firstName || 'Unknown';
    const userLastName = userDetails.lastName || 'Unknown';
    const userBio = userDetails.aboutMe || 'Bio not provided';
    const isUserAlreadySeated = spaceDetails.units.some((unit) =>
      unit.tables.some((table) =>
        table.occupiedChairs?.some(
          (chair) => chair.name === userFirstName && chair.surname === userLastName
        )
      )
    );
    if (isUserAlreadySeated) {
      toast.warning('You are already sitting on a chair. Please exit the current chair before selecting a new one.');
      return;
    }
    const isChairOccupied = table.occupiedChairs.some(chair => chair.index === chairIndex);
    if (isChairOccupied) {
      toast.warning('This chair is already occupied.');
      return;
    }
    table.occupiedChairs.push({
      index: chairIndex,
      name: userFirstName,
      surname: userLastName,
      aboutMe: userBio
    });
    setSpaceDetails({ ...spaceDetails });
    setIsUserSeated(true);
    try {
      const sanitizedUnits = spaceDetails.units.map((unit) => ({
        ...unit,
        tables: unit.tables.map((table) => ({
          ...table,
          occupiedChairs: table.occupiedChairs || []
        }))
      }));
      await updateDoc(doc(db, 'virtual-offices', spaceId), { units: sanitizedUnits });
      toast.success('You have successfully sat on the chair.');
      setSelectedRoom(`${unitName}-meeting`);
      setIsJitsiModalOpen(true);
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error sitting on the chair.');
    }
  };

  const handleAvatarClick = (chairDetails) => {
    setModalData(chairDetails); 
    setIsUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false); 
    setModalData(null); 
  };


  const handleExitChair = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
    const userFirstName = userDetails.firstName || 'Unknown';
    const userLastName = userDetails.lastName || 'Unknown';
    const updatedTables = unit.tables.map((table) => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        (chair) => chair.name !== userFirstName || chair.surname !== userLastName
      )
    }));
    const updatedUnits = spaceDetails.units.map((u) =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    setIsUserSeated(false);
    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), { units: updatedUnits });
      toast.success('You have exited the chair.');
    } catch (error) {
      console.error('Error exiting chair: ', error);
      toast.error('Error exiting the chair.');
    }
  };

  const handleExitRoom = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
    const updatedTables = unit.tables.map(table => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        chair => chair.name !== userDetails.firstName || chair.surname !== userDetails.lastName
      )
    }));
    const updatedUnits = spaceDetails.units.map(u =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), { units: updatedUnits });
      setCurrentUnit(null);
      toast.success('You have exited the room.');
    } catch (error) {
      console.error('Error exiting room: ', error);
      toast.error('Error exiting the room.');
    }
  };

  if (isCheckingInvitation || loading) {
    return (
      <div className="loader-container">
        <DotLoader size={60} color={'#ce2b7c'} />
      </div>
    );
  }

  if (!isInvited) {
    return <div>You do not have access to this virtual space.</div>;
  }

  return (
    <div className="glass-back">
      <BackgroundStyleLight />
      {!currentUnit && (
        <section className="virtual-space-sec">
          <div className="container-fluid">
            <div className="content-wrapper-header">
              <div className="content-wrapper-context">
                <h3 className="img-content">
                  <img src={imgDark} width="50" style={{ paddingRight: '6px' }} alt="logo" />
                  {spaceDetails.officeName}
                </h3>
                <div className="content-text">{spaceDetails.description}</div>
                <button className="content-button">Join virtual space</button>
              </div>
              <img className="content-wrapper-img" src="https://assets.codepen.io/3364143/glass.png" alt="glass" />
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="auto-container">
          {!currentUnit && (
            <div className="row clearfix">
              <div className="short-box clearfix mt-4">
                <div className="text-center">
                  <p>Showing {spaceDetails.units.length} Units</p>
                </div>
              </div>
              {spaceDetails.units.map((unit, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-4">
                  <div className="card-unit">
                    <div className="card-img-unit">
                      <img src="https://dl.dropbox.com/s/u3j25jx9tkaruap/Webp.net-resizeimage.jpg?raw=1" alt="Unit" />
                    </div>
                    <div className="desc">
                      <h6 className="primary-text">Virtual Space</h6>
                      <h6 className="secondary-text">{unit.unitName}</h6>
                    </div>
                    <button className="primary-text btn-pp" onClick={() => handleUnitClick(unit.unitName)}>
                      Join Space
                    </button>
                    <div className="details">

                    <div className="rating">
  <h6 className="primary-text">
    {spaceDetails.units.reduce(
      (totalSeats, unit) =>
        totalSeats +
        unit.tables.reduce((sum, table) => sum + (Number(table.chairs) || 0), 0), // Ensure table.chairs is a number
      0
    )}
  </h6>
  <h6 className="secondary-text">Seats</h6>
</div>


                      <div className="activity">
                        <h6 className="primary-text">{unit.tables.length}</h6>
                        <h6 className="secondary-text">Tables</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {currentUnit && (
        <section className="current-unit-section blur-backgroundz">
          <BackgroundStyleLight />
          <div className="event-header">
            <div className="logo">
              <img alt="Logo" className='imgLogoE' />
              <span>{currentUnit}</span>
            </div>
            <div className="icons">
              <i className="fas fa-users"></i>
              <div className="profile">{`${userDetails.firstName?.charAt(0)}${userDetails.lastName?.charAt(0)}`}</div>
              {isUserSeated && (
                <button onClick={handleExitChair} type="button" className="ml-2 btn btn-sm light btn-secondary">
                  Exit Chair
                </button>
              )}
              <button onClick={handleExitRoom} type="button" className="ml-2 btn btn-sm light btn-danger">
                Leave the room
              </button>
            </div>
          </div>

      

          <div className="tables-container">
            <div className="room-layout">
              <div className="tables-grid container room-cards-container">
                {spaceDetails.units
                  .find((unit) => unit.unitName === currentUnit)
                  .tables.map((table, index) => {
                    // Calculate how many chairs should go on each side
                    const { topRow, bottomRow, leftRow, rightRow } = calculateChairDistribution(table.chairs);
                    return (
                      <div className="table-card" key={table.tableName}>
                        <div className="meeting-title">{table.tableName}</div>
                        <div className="table-container">
                          <div className="table-area">
                            {/* Top row */}
                            <div className="row-chairs">
                              {Array(topRow).fill().map((_, i) => {
                                const occupied = table.occupiedChairs?.find(chair => chair.index === i);
                                return (
                                  <div
                                    key={`top-${i}`}
                                    onClick={() => {
                                      if (occupied) {
                                        handleAvatarClick(occupied);
                                      } else {
                                        handleSitOnChair(currentUnit, index, i);
                                      }
                                    }}
                                    className="chair"
                                  >
                                    {occupied && (
                                      <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px'  />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-2">
                              {/* Left vertical chairs */}
                              <div className="vertical-chairs me-1">
                                {Array(leftRow).fill().map((_, i) => {
                                  const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow + rightRow);
                                  return (
                                    <div
                                      key={`left-${i}`}
                                      onClick={() => {
                                        if (occupied) {
                                          handleAvatarClick(occupied);
                                        } else {
                                          handleSitOnChair(currentUnit, index, i + topRow + rightRow);
                                        }
                                      }}
                                      className="chair"
                                    >
                                      {occupied && (
                                        <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px'  />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              {/* Table */}
                              <div className="table-rect">
                                {table.tableLogoUrl ? (
                                  <img src={table.tableLogoUrl} alt="Table Logo" className="table-logo" width='38' />
                                ) : (
                                  <img src={eLogo} alt="Table Logo" className="table-logo" width='35' />
                                )}
                              </div>
                              {/* Right vertical chairs */}
                              <div className="vertical-chairs ms-1">
                                {Array(rightRow).fill().map((_, i) => {
                                  const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow);
                                  return (
                                    <div
                                      key={`right-${i}`}
                                      onClick={() => {
                                        if (occupied) {
                                          handleAvatarClick(occupied);
                                        } else {
                                          handleSitOnChair(currentUnit, index, i + topRow);
                                        }
                                      }}
                                      className="chair"
                                    >
                                      {occupied && (
                                        <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px'  />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {/* Bottom row */}
                            <div className="row-chairs">
                              {Array(bottomRow).fill().map((_, i) => {
                                const occupied = table.occupiedChairs?.find(chair => chair.index === i + topRow + rightRow + leftRow);
                                return (
                                  <div
                                    key={`bottom-${i}`}
                                    onClick={() => {
                                      if (occupied) {
                                        handleAvatarClick(occupied);
                                      } else {
                                        handleSitOnChair(currentUnit, index, i + topRow + rightRow + leftRow);
                                      }
                                    }}
                                    className="chair"
                                  >
                                    {occupied && (
                                      <Avatar name={`${occupied.name} ${occupied.surname}`} size="25" round='6px'  />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <button className="join-btn">Join</button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* <div className="fab-wrapper">
            <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
            <label className="fabF" htmlFor="fabCheckbox">
              <span className="fab-dots fab-dots-1"></span>
              <span className="fab-dots fab-dots-2"></span>
              <span className="fab-dots fab-dots-3"></span>
            </label>
            <div className="fab-wheel">
              <a className="fab-action fab-action-1" data-tooltip-id="my-tooltip-help">
                <i className="fas fa-question"></i>
              </a>
              <a className="fab-action fab-action-2" data-tooltip-id="my-tooltip-polls">
                <i className="fas fa-poll"></i>
              </a>
              <a className="fab-action fab-action-3" data-tooltip-id="my-tooltip-qa">
                <i className="fas fa-comment-alt"></i>
              </a>
              <a className="fab-action fab-action-4" data-tooltip-id="my-tooltip-chat">
                <i className="fas fa-comments"></i>
              </a>
              <Tooltip id="my-tooltip-help" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Help!</h5>
                <p style={{ color: '#fff' }}>eConnect Chat Support</p>
              </Tooltip>
              <Tooltip id="my-tooltip-chat" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Chat!</h5>
                <p style={{ color: '#fff' }}>Enjoy interacting with the people in this webinar!</p>
              </Tooltip>
              <Tooltip id="my-tooltip-qa" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Q&A!</h5>
                <p style={{ color: '#fff' }}>An easy way for everyone to ask questions</p>
              </Tooltip>
              <Tooltip id="my-tooltip-polls" style={{ background: "#000" }}>
                <h5 style={{ paddingTop: "8px", color: '#fff' }}>Polls</h5>
                <p style={{ color: '#fff' }}>Get a quick pulse of the audience</p>
              </Tooltip>
            </div>
          </div> */}


        </section>
      )}

      <Modal isOpen={isUserModalOpen} onRequestClose={handleCloseUserModal} style={customStylesBio}>
        {modalData && (
          <div className="text-center">
            <Avatar name="Mduduzi Mahlangu" color={"#8d14ff"} size="62" round={true} />
            <br />
            <p className="mt-2"><strong>{modalData.name} {modalData.surname}</strong></p>
            {console.log("Modal Data: ", modalData)}
            <p>{modalData.aboutMe ? modalData.aboutMe : "Bio not available"}</p>
            <button onClick={handleCloseUserModal} type="button" className="btn btn-sm light btn-danger">
              Close
            </button>
          </div>
        )}
      </Modal>

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
        <h4 style={{ color: '#000' }}>
          Enter: <span style={{ fontWeight: 'bold' }}>{selectedUnit}</span>
        </h4>
        <form className="profile-form">
          <div className="row">
            <div className="col-lx-6 m-b30">
              <label className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.name}
                onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-2">
              <label className="form-label">Surname:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.surname}
                onChange={(e) => setUserDetails({ ...userDetails, surname: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-4">
              <label className="form-label">Bio:</label>
              <textarea
                rows="8"
                className="form-control"
                value={userDetails.aboutMe}
                onChange={(e) => setUserDetails({ ...userDetails, aboutMe: e.target.value })}
              />
            </div>
            <div className="card-footer">
              <div className="text-center">
                <button type="button" className="theme-btn btn-style-five" onClick={handleEnterRoom}>
                  Enter Room
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <EconnectMeetingModal
        isOpen={isJitsiModalOpen}
        onClose={() => setIsJitsiModalOpen(false)}
        roomName={selectedRoom}
        userName={`${userDetails.firstName} ${userDetails.lastName}`}
      />
    </div>
  );
};

export default VirtualOfficeSpaceUnits;
