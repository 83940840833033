import React, { useState } from "react";
import "../dashboardstyle/css/style.css";
import Avatar from "react-avatar";
import { signOut } from "firebase/auth";

import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const OrganiseSide = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [companyOpen, setCompanyOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  const currentYear = new Date().getFullYear();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("User logged out");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleCompanyClick = () => {
    setCompanyOpen(!companyOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCompanyOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="dlabnav">
        <div className="dlabnav-scroll">
          <ul className="metismenu" id="menu">

            <li
              className={activeTab === "Dashboard" ? "active" : ""}
              onClick={() => setActiveTab("Dashboard")}
            >
              <a className="">
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </a>
            </li>


            <li
  className={
    activeTab === "CompanyProfile" ||
    activeTab === "Team" ||
    activeTab === "Library" ||
    activeTab === "CompanyWebsite" ||
    activeTab === "VirtualSpaces" ||
    companyOpen
      ? "mm-active"
      : ""
  }
  ref={dropdownRef}
>
  <a
    className="has-arrow"
    href="javascript:void(0)"
    aria-expanded={
      activeTab === "CompanyProfile" ||
      activeTab === "Team" ||
      activeTab === "Library" ||
      activeTab === "CompanyWebsite" ||
      activeTab === "VirtualSpaces" ||
      companyOpen
    }
    onClick={handleCompanyClick}
  >
    <i className="fas fa-building"></i>
    <span
      className={`nav-text ${
        activeTab === "CompanyProfile" ||
        activeTab === "Team" ||
        activeTab === "Library" ||
        activeTab === "CompanyWebsite" ||
        activeTab === "VirtualSpaces"
          ? "active"
          : ""
      }`}
    >
      Company
    </span>
  </a>
  <ul
    aria-expanded={
      activeTab === "CompanyProfile" ||
      activeTab === "Team" ||
      activeTab === "Library" ||
      activeTab === "CompanyWebsite" ||
      activeTab === "VirtualSpaces" ||
      companyOpen
    }
    className={`left mm-collapse ${
      activeTab === "CompanyProfile" ||
      activeTab === "Team" ||
      activeTab === "Library" ||
      activeTab === "CompanyWebsite" ||
      activeTab === "VirtualSpaces" ||
      companyOpen
        ? "mm-show"
        : ""
    }`}
  >
    <li
      className={activeTab === "CompanyProfile" ? "mm-active" : ""}
      onClick={() => {
        setActiveTab("CompanyProfile");
        setCompanyOpen(false);
      }}
    >
      <a className="nav-text-dark">
       
        <span
          className={`nav-text ${
            activeTab === "CompanyProfile" ? "active" : ""
          }`}
        >
          - Company Profile
        </span>
      </a>
    </li>
    <li
      className={activeTab === "Team" ? "mm-active" : ""}
      onClick={() => {
        setActiveTab("Team");
        setCompanyOpen(false);
      }}
    >
      <a className="nav-text-dark">
    
        <span className={`nav-text ${activeTab === "Team" ? "active" : ""}`}>
          - Team
        </span>
      </a>
    </li>
    <li
      className={activeTab === "Library" ? "mm-active" : ""}
      onClick={() => {
        setActiveTab("Library");
        setCompanyOpen(false);
      }}
    >
      <a className="nav-text-dark">
      
        <span className={`nav-text ${activeTab === "Library" ? "active" : ""}`}>
          - Library
        </span>
      </a>
    </li>
    <li
      className={activeTab === "CompanyWebsite" ? "mm-active" : ""}
      onClick={() => {
        setActiveTab("CompanyWebsite");
        setCompanyOpen(false);
      }}
    >
      <a className="nav-text-dark">
        
        <span
          className={`nav-text ${
            activeTab === "CompanyWebsite" ? "active" : ""
          }`}
        >
          - Company Website
        </span>
      </a>
    </li>
    <li
      className={activeTab === "VirtualSpace" ? "mm-active" : ""}
      onClick={() => {
        setActiveTab("VirtualSpace");
        setCompanyOpen(false);
      }}
    >
      <a className="nav-text-dark">
        
        <span
          className={`nav-text ${
            activeTab === "VirtualSpace" ? "active" : ""
          }`}
        >
            - Virtual Spaces
        </span>
      </a>
    </li>
  </ul>
</li>


            
            <li
              className={activeTab === "Events" ? "active" : ""}
              onClick={() => setActiveTab("Events")}
            >
              <a className="">
                <i className="fas fa-info-circle"></i>
                <span className="nav-text">Events</span>
              </a>
            </li>
          
           
            <li
              className={activeTab === "Integrations" ? "active" : ""}
              onClick={() => setActiveTab("Integrations")}
            >
              <a className="">
                <i className="fas fa-plug"></i>
                <span className="nav-text">Integrations</span>
              </a>
            </li>
            <li
              className={activeTab === "Billing" ? "active" : ""}
              onClick={() => setActiveTab("Billing")}
            >
              <a className="">
                <i className="fas fa-money-bill-wave"></i>
                <span className="nav-text">Billing</span>
              </a>
            </li>
            <li
              className={activeTab === "Analytics" ? "active" : ""}
              onClick={() => setActiveTab("Analytics")}
            >
              <a className="">
                <i className="fas fa-chart-line"></i>
                <span className="nav-text">Analytics</span>
              </a>
            </li>
            <li
              className={activeTab === "Audience" ? "active" : ""}
              onClick={() => setActiveTab("Audience")}
            >
              <a className="">
                <i className="fas fa-users"></i>
                <span className="nav-text">Audience</span>
              </a>
            </li>

         

            <li
              className={activeTab === "Profile" ? "active" : ""}
              onClick={() => setActiveTab("Profile")}
            >
              <a className="">
                <i className="fas fa-user"></i>
                <span className="nav-text">Profile</span>
              </a>
            </li>

            <li
              className={activeTab === "ChangePassword" ? "active" : ""}
              onClick={() => setActiveTab("ChangePassword")}
            >
              <a className="">
                <i className="fas fa-unlock-alt"></i>
                <span className="nav-text">Change Password</span>
              </a>
            </li>

            <li>
              <a className="" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i>
                <span className="nav-text">Logout</span>
              </a>
            </li>
          </ul>
          {/* <div className="side-bar-profile">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="side-bar-profile-imgz">
                <Avatar name="Foo Bar" size="42" round={true} />
              </div>
              <div className="profile-info1">
                <h5>Levi Siregar</h5>
                <span>leviregar@mail.com</span>
              </div>
              <div className="profile-button">
                <i className="fas fa-caret-downd scale5 text-light"></i>
              </div>
            </div>
          </div> */}
          <div className="copyright">
            <p>eConnect © {currentYear}</p>
            <p className="fs-12">
              Made with <span className="fas fa-heart heartbeat"></span> by
              Think Tank
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganiseSide;
