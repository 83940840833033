import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To access the document ID from the URL
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure this is your Firebase Firestore import
import { CircleLoader } from "react-spinners";
import { toast } from "react-toastify";

const EventDetails = () => {
  const { eventId } = useParams(); // Get document ID from the URL
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const docRef = doc(db, "event-webinars", eventId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEventDetails(docSnap.data());
        } else {
          toast.error("No such document!");
        }
      } catch (error) {
        toast.error("Error fetching event details: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventDetails();
    }
  }, [eventId]);

  if (loading) {
    return (
      <div className="loader-containerz">
        <CircleLoader size={50} color={"#ce2b7c"} loading={loading} />
      </div>
    );
  }

  if (!eventDetails) {
    return <div>No event details found.</div>;
  }

  console.log('Component Rendered');

  return (
    <div className="event-details-container">
      <h1>{eventDetails.eventName}</h1>
      <p>{eventDetails.additionalInfo}</p>
      <p>
        <strong>Organized by:</strong> {eventDetails.organizedBy}
      </p>
      <p>
        <strong>Support Email:</strong> {eventDetails.supportEmail}
      </p>
      <p>
        <strong>Start Date:</strong> {eventDetails.startDate} {eventDetails.startTime}
      </p>
      {eventDetails.endDate && (
        <p>
          <strong>End Date:</strong> {eventDetails.endDate} {eventDetails.endTime}
        </p>
      )}
      <p>
        <strong>Attendance:</strong> {eventDetails.attendance}
      </p>
    </div>
  );
};

export default EventDetails;
