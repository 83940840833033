import React from 'react'

const BackgroundStyleLight = () => {
  return (
  
    <div class="background">
 
</div>
  )
}

export default BackgroundStyleLight
